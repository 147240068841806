<template>
  <v-container>
    <!-- <v-text style="font-size: 24px; font-weight: 800">SMS API Settings</v-text> -->
    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">API Key</v-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field dense solo v-model="sms_key" type="password" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">API Secret</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field dense solo v-model="sms_secret" type="password" />
            <v-btn
              color="primary"
              class="ml-2 mr-0"
              :loading="generateLoading"
              @click="handleGenerateBtn"
              >Generate token</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">SMS Sender</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              rows="2"
              dense
              :rules="[rules.required]"
              placeholder="+44 00 000 0000"
              solo
              v-model="sms_sender"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">Archive</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              dense
              solo
              type="number"
              v-model="archive_days"
              placeholder="1"
              suffix="Days"
              :min="interval_al2 + 1"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex text-center justify-center mt-3" @click="update">
      <v-btn class="primary" :loading="isLoading">Save</v-btn>
    </div>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIdoUpdateSmsSetting, APIdoSmsGenerate } from "@/api/common/api";

export default {
  data() {
    return {
      sms_key: "",
      sms_time: "",
      sms_secret: "",
      sms_text: "",
      sms_sender: "",
      sms_interval: 1,
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      isLoading: false,
      generateLoading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    // this.sms_key = this.settingValue.sms_key;
    this.sms_time = this.settingValue.sms_time;
    // this.sms_secret = this.settingValue.sms_secret;
    this.sms_text = this.settingValue.sms_text;
    this.sms_sender = this.settingValue.sms_sender;
    this.archive_days = parseInt(this.settingValue.archive_days);
  },
  computed: {
    ...mapGetters({
      settingValue: "getSettingsValue",
    }),
  },
  methods: {
    ...mapActions({
      doGetSettings: "doGetSettings",
    }),
    update() {
      const postData = {
        sms_key: this.sms_key,
        sms_secret: this.sms_secret,
        sms_sender: this.sms_sender,
        archive_days: this.archive_days,
      };
      this.isLoading = true;
      APIdoUpdateSmsSetting(postData)
        .then((res) => {
          this.notification = true;
          this.notificationTxt = "Successfully Updated";
          this.notificationColor = "success";
          this.doGetSettings();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
    handleGenerateBtn() {
      this.generateLoading = true;
      APIdoSmsGenerate()
        .then((res) => {
          this.notification = true;
          this.notificationTxt = "Token successfully generated";
          this.notificationColor = "success";
          this.doGetSettings();
          this.generateLoading = false;
        })
        .catch((error) => {
          this.generateLoading = false;

          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>
