<template>
  <v-container>
    <!-- <v-text style="font-size: 24px; font-weight: 800">SMTP Settings</v-text> -->
    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">SMTP Host</v-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="smtp.mailserver.cp.uk"
              v-model="smtp_host"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">SMTP Port</v-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              type="number"
              placeholder="945"
              :rules="[rules.required]"
              v-model="smtp_port"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">SMTP User</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="sender@mailserver.co.uk"
              :rules="[rules.required, rules.email]"
              v-model="smtp_user"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">From Address</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="email@mailserver.co.uk"
              :rules="[rules.required, rules.email]"
              v-model="smtp_sender"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">SMTP Password</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              :rules="[rules.required]"
              v-model="smtp_pass"
              type="password"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">From Name</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              placeholder="GMB Leavers"
              :rules="[rules.required]"
              v-model="smtp_name"
              solo
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex text-center justify-center">
      <v-btn class="primary" @click="update" :loading="isLoading">Save</v-btn>
    </div>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIdoUpdateSmtpSetting } from "@/api/common/api";

export default {
  data() {
    return {
      smtp_host: "",
      smtp_user: "",
      smtp_pass: "",
      smtp_port: "",
      smtp_sender: "",
      smtp_name: "",
      archive_days: 0,
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      isLoading: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        number: (value) => {
          Number.isInteger(Number(value)) ||
            "The value must be an integer number";
        },
      },
    };
  },
  mounted() {
    this.smtp_host = this.settingValue.smtp_host;
    this.smtp_user = this.settingValue.smtp_user;
    this.smtp_pass = this.settingValue.smtp_pass;
    this.smtp_port = this.settingValue.smtp_port;
    this.smtp_sender = this.settingValue.smtp_sender;
    this.smtp_name = this.settingValue.smtp_name;
  },
  computed: {
    ...mapGetters({
      settingValue: "getSettingsValue",
    }),
  },
  methods: {
    ...mapActions({
      doGetSettings: "doGetSettings",
    }),
    update() {
      const postData = {
        smtp_host: this.smtp_host,
        smtp_user: this.smtp_user,
        smtp_pass: this.smtp_pass,
        smtp_port: this.smtp_port,
        smtp_sender: this.smtp_sender,
        smtp_name: this.smtp_name,
      };
      this.isLoading = true;
      APIdoUpdateSmtpSetting(postData)
        .then((res) => {
          this.notification = true;
          this.notificationTxt = "Successfully Updated";
          this.notificationColor = "success";
          this.doGetSettings();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>
