<template>
  <v-container>
    <!-- <v-text style="font-size: 24px; font-weight: 800">Email Addresses</v-text> -->

    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">London</v-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="london@gmb.org.uk"
              v-model="email_lo"
              :rules="[rules.required, rules.email]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">Scotland</v-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="scotland@gmb.org.uk"
              v-model="email_sc"
              :rules="[rules.required, rules.email]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">Midland</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="midland@gmb.org.uk"
              v-model="email_mi"
              :rules="[rules.required, rules.email]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">Southern </v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="southern@gmb.org.uk"
              v-model="email_so"
              :rules="[rules.required, rules.email]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">Northern</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              solo
              placeholder="northern@gmb.org.uk"
              v-model="email_no"
              :rules="[rules.required, rules.email]"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px"
              >Wales & South West
            </v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              placeholder="wales@gmb.org.uk"
              v-model="email_sw"
              :rules="[rules.required, rules.email]"
              solo
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" sm="4" class="mt-3">
            <v-text style="color: black; font-size: 16px">North West</v-text>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <v-text-field
              style="max-width: 300px"
              dense
              placeholder="northwest@gmb.org.uk"
              v-model="email_nw"
              :rules="[rules.required, rules.email]"
              solo
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex text-center justify-center" @click="update">
      <v-btn class="primary" :loading="isLoading">Save</v-btn>
    </div>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIdoUpdateEmailSetting } from "@/api/common/api";

export default {
  data() {
    return {
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      isLoading: false,
      email_lo: "",
      email_mi: "",
      email_no: "",
      email_nw: "",
      email_sc: "",
      email_so: "",
      email_sw: "",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.email_lo = this.settingValue.email_lo;
    this.email_mi = this.settingValue.email_mi;
    this.email_no = this.settingValue.email_no;
    this.email_nw = this.settingValue.email_nw;
    this.email_sc = this.settingValue.email_sc;
    this.email_sw = this.settingValue.email_sw;
    this.email_so = this.settingValue.email_so;
  },
  computed: {
    ...mapGetters({
      settingValue: "getSettingsValue",
    }),
  },

  methods: {
    ...mapActions({
      doGetSettings: "doGetSettings",
    }),
    update() {
      const postData = {
        email_lo: this.email_lo,
        email_mi: this.email_mi,
        email_no: this.email_no,
        email_nw: this.email_nw,
        email_sc: this.email_sc,
        email_sw: this.email_sw,
        email_so: this.email_so,
      };
      this.isLoading = true;
      APIdoUpdateEmailSetting(postData)
        .then((res) => {
          this.notification = true;
          this.notificationTxt = "Successfully Updated";
          this.notificationColor = "success";
          this.doGetSettings();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>
