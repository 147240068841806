<template>
  <v-container id="regular-tables" fluid tag="section" class="pt-10">
    <v-tabs v-model="tabs" fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#mobile-tabs-5-1" class="primary--text">
        <v-icon class="mr-2">mdi-content-save-cog-outline </v-icon>
        <v-text>SMS API Settings</v-text>
      </v-tab>

      <v-tab href="#mobile-tabs-5-2" class="primary--text">
        <v-icon class="mr-2">mdi-email-sync-outline</v-icon>
        <v-text>SMTP Settings</v-text>
      </v-tab>

      <v-tab href="#mobile-tabs-5-3" class="primary--text">
        <v-icon class="mr-2">mdi-at</v-icon>
        <v-text>Email Addresses</v-text>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item value="mobile-tabs-5-1">
        <SmsTabView />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-2">
        <SMTPTabView />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-3">
        <EmailTabView />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import SmsTabView from "../component/Settings/SmsTab.vue";
import SMTPTabView from "../component/Settings/SMTPTab.vue";
import EmailTabView from "../component/Settings/EmailTab.vue";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tabs: null,
    };
  },
  components: {
    SmsTabView,
    SMTPTabView,
    EmailTabView,
  },
  mounted() {
    if (this.getUserDetails.user_type !== "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
  },
};
</script>
