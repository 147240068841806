import { render, staticRenderFns } from "./EmailTab.vue?vue&type=template&id=7c6b37a4&"
import script from "./EmailTab.vue?vue&type=script&lang=js&"
export * from "./EmailTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports